'use client';
import { environment } from '@/environment';
import {
  ComponentsConfigProvider,
  GrimmeComponentsProvider,
  withNotificationsReducer,
} from '@grimme/components';
import {
  AnalyticsProvider,
  ConsentState,
  useConsent,
  UserCentricsServiceId,
} from '@grimme/gdap';
import { useSession } from '@grimme/next-grimme-auth';
import theme from '@grimme/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { configureStore } from '@reduxjs/toolkit';
import { FC, PropsWithChildren, useState } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { Provider as ReduxProvider } from 'react-redux';
import { FeatureFlagsProvider } from './feature-flags-provider';
import { createInstance, Resource } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { initTranslations } from '../../lib/i18n/i18n';
import { GrimmeDataPerRegionProvider } from '../grimme-data-per-region/provider';
import { SentryProvider } from './sentry/sentry';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';

const store = configureStore({
  reducer: withNotificationsReducer({}),
});
export const Providers: FC<
  PropsWithChildren<{
    contactsPerRegionData: any;
    i18nConfigs?: { resources: Resource };
    iconsList: IconDefinition[];
    locale: string;
  }>
> = ({ children, i18nConfigs, locale, contactsPerRegionData, iconsList }) => {
  const i18n = createInstance();
  initTranslations(locale, i18n, i18nConfigs?.resources);
  const { commitHash, insightsKey, projectName, appVersion } = environment;
  library.add(...(iconsList ?? []));

  const [queryClient] = useState(() => new QueryClient());

  const { data } = useSession();
  const isAiConsentGiven = useConsent(
    UserCentricsServiceId.AZURE_APPLICATION_INSIGHTS,
  );

  // TODO: Remove as soon as the theme is updated with the new styles.
  const websiteTheme = theme;
  websiteTheme.palette.background.default = theme.palette.grey[100];

  return (
    <>
      <I18nextProvider i18n={i18n}>
        <ComponentsConfigProvider
          language={i18n.language}
          t={i18n.t as (key: string, defaultValue?: string) => string}
        >
          <ReduxProvider store={store}>
            <QueryClientProvider client={queryClient}>
              <Hydrate state={{}}>
                <AnalyticsProvider
                  defaultEnvelopeData={{
                    commit: commitHash,
                    project: projectName,
                    version: appVersion,
                  }}
                  instrumentationKey={insightsKey}
                  isConsentGiven={isAiConsentGiven === ConsentState.GRANTED}
                  username={data?.user?.email}
                >
                  <SentryProvider>
                    <ThemeProvider theme={websiteTheme}>
                      <CssBaseline />
                      <GrimmeComponentsProvider>
                        <FeatureFlagsProvider>
                          <GrimmeDataPerRegionProvider
                            value={{
                              contactsRaw: contactsPerRegionData,
                            }}
                          >
                            {children}
                          </GrimmeDataPerRegionProvider>
                        </FeatureFlagsProvider>
                      </GrimmeComponentsProvider>
                    </ThemeProvider>
                  </SentryProvider>
                </AnalyticsProvider>
              </Hydrate>
            </QueryClientProvider>
          </ReduxProvider>
        </ComponentsConfigProvider>
      </I18nextProvider>
    </>
  );
};
