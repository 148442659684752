import { i18nConfig } from '@/lib/i18n/i18n.config';
import { createInstance, i18n, Resource } from 'i18next';
import Backend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import { cache } from 'react';

const i18nextBackendOptions = {
  backendOptions: [
    {
      addPath: '',

      // expiration
      expirationTime: 24 * 60 * 60 * 1000,

      loadPath: `${process.env.NEXT_PUBLIC_TRANSLATIONS_API}/translations?language={{lng}}`,
    },
  ],
  backends: [HttpBackend],
};

export async function initTranslations(
  locale: string,
  i18nInstance?: i18n,
  resources?: Resource,
) {
  i18nInstance = i18nInstance || createInstance();

  i18nInstance.use(Backend);

  await i18nInstance.init({
    backend: i18nextBackendOptions,
    fallbackLng: locale,
    lng: locale,
    react: { useSuspense: false },
    resources,
    supportedLngs: i18nConfig.locales,
  });

  return i18nInstance;
}

export default cache(initTranslations);
