'use client';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { Footer } from '@grimme/components';
import { Box, GlobalStyles, styled } from '@mui/material';
import { GlobalSettings } from '@/lib/butter/butter-types';
import { NavbarWithContact } from '@/ui/navbar-with-contact/navbar-with-contact';
import { ButterPage } from '@/lib/butter/butter-types/misc/butterAPI-types';
// import { PreviewModeButton } from "../preview-mode-button";

const StyledBox = styled(Box)(() => ({
  minHeight: '100vh',
}));

export type LayoutProps = {
  children: React.ReactNode;
  globalSettings: ButterPage<GlobalSettings>;
  withoutContactAssistant?: boolean;
};

export function Layout({
  children,
  globalSettings,
  withoutContactAssistant = false,
}: LayoutProps) {
  const footerData = globalSettings.fields.footer;
  const topLinks = globalSettings.fields.top_links;
  const socialLinks = globalSettings.fields.social_links;
  const navigationLinks = globalSettings.fields.navigation_menu;

  // preview = need to come form useSearchParams();

  const parsedNavigationLinksForFooter = navigationLinks
    .map((l) => l.fields)
    .map((link) => ({
      label: link.label,
      url: link.url,
    }));

  return (
    <>
      {/* {preview && <PreviewModeButton />} */}
      <NavbarWithContact
        greeting={globalSettings.fields.greeting}
        loggedInMenu={globalSettings.fields.logged_in_menu}
        loggedOutMenu={globalSettings.fields.logged_out_menu}
        more={globalSettings.fields.more}
        navbarData={globalSettings.fields.navbar}
        navigationLinks={navigationLinks}
        socialLinks={socialLinks}
        topLinks={topLinks}
        withoutContactAssistant={withoutContactAssistant}
      />
      <StyledBox>{children}</StyledBox>
      <Footer
        data={{
          company_information: [
            {
              city: footerData.city,
              company_name: footerData.company_name,
              email: footerData.email,
              phone: footerData.phone,
              postal_code: footerData.postal_code,
              street_address: footerData.street_address,
              website: footerData.website,
            },
          ],
          legal_information_links: footerData.legal_information_links,
          title: footerData.title,
        }}
        sitemap={[
          {
            caption: footerData.sitemap_title,
            links: parsedNavigationLinksForFooter,
          },
          {
            caption: topLinks.title,
            links: topLinks.links,
          },
        ]}
        socialLinks={socialLinks.links.map((socialLink) => ({
          icon: {
            iconName: socialLink.icon,
            prefix: socialLink.icon_type,
          } as IconLookup,
          url: socialLink.url,
        }))}
      />
      <GlobalStyles styles={{ html: { scrollBehavior: 'smooth' } }} />
    </>
  );
}
