import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@grimme/components';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { Box, Checkbox, Link, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)(() => ({
  fontWeight: 600,
  textDecoration: 'none',
}));

const StyledBox = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginTop: theme.spacing(1),
}));

interface WhatsappConsentDialogProps {
  contactNumber: string;
  onCancel: () => void;
  onConfirm: () => void;
  open?: boolean;
}

export const WhatsappConsentDialog = (props: WhatsappConsentDialogProps) => {
  const { contactNumber, onCancel, onConfirm, open } = props;

  const [checked, setChecked] = React.useState(false);
  const insights = useAppInsightsContext();
  const { t } = useTranslation();

  return (
    <Dialog
      confirmButtonProps={{ disabled: !checked }}
      icon={<FontAwesomeIcon icon={faWhatsapp} />}
      labelCancel={t(
        'myGRIMME_products_whatsapp_consent_dialog_cancel',
        'Abbrechen',
      )}
      labelConfirm={t(
        'myGRIMME_products_whatsapp_consent_dialog_confirm',
        'Abschicken',
      )}
      open={open ?? false}
      onCancel={onCancel}
      onConfirm={() => {
        localStorage.setItem('allowWhatsapp', Date.now().toFixed(0));
        window.open(`https://wa.me/${contactNumber}`);
        insights?.trackEvent({
          name: 'Contacted Rep',
          properties: {
            method: 'whatsapp',
            rep: contactNumber,
          },
        });
        onConfirm();
      }}
      title={t('myGRIMME_products_whatsapp_headline', 'Whatsapp')}
    >
      <div>
        {t(
          'myGRIMME_products_whatsapp_consent_description',
          'Bevor es weitergehen kann: Deine Privatsphäre ist uns wichtig. Unsere Datenschutzbedingungen findest Du <a>hier</a>',
          {
            a: (text: string) => (
              <StyledLink href="https://my.grimme.com/gdpr" target="_blank">
                {text}
              </StyledLink>
            ),
          },
        )}
      </div>

      <StyledBox>
        <Checkbox
          data-testid="whatsapp-checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <Typography variant="body1">
          {t(
            'myGRIMME_products_whatsapp_consent_checkbox_text',
            'Ich habe die Datenschutzbedingungen zur Kenntnis genommen.',
          )}
        </Typography>
      </StyledBox>
    </Dialog>
  );
};
