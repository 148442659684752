import { ConsentState, useConsent, UserCentricsServiceId } from '@grimme/gdap';
import * as Sentry from '@sentry/nextjs';
import { PropsWithChildren, useEffect } from 'react';

export function SentryProvider({ children }: PropsWithChildren<unknown>) {
  const isSentryConsentGiven = useConsent(UserCentricsServiceId.SENTRY);

  useEffect(() => {
    const clientOptions = Sentry.getClient()?.getOptions();

    if (clientOptions) {
      clientOptions.enabled = isSentryConsentGiven === ConsentState.GRANTED;
    }
  }, [isSentryConsentGiven]);

  return <>{children}</>;
}
